import { Box, Flex, Image, Text, useDirection, useMantineColorScheme } from "@mantine/core";
import { useFetch, useInterval } from "@mantine/hooks";
import { useEffect, useRef, useState } from "react";
import { NewsFeedHeader } from ".";

export const NewsFeedSlider = ({
    style = {},
    withHeader = true,
}: {
    style?: React.CSSProperties,
    withHeader?: boolean,
}) => {

    const limit = 15;
    const timeToChange = 10; // seconds

    const { colorScheme } = useMantineColorScheme();
    const [currentData, setCurrentData] = useState<any>({});
    const [currentPost, setCurrentPost] = useState<any>({});
    const { dir } = useDirection();
    const { data, error, refetch, abort } = useFetch<any>(
        `https://apirouter.964media.com/v1/ar/posts/timeline?limit=${limit}`,
    );

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 10000);
        return () => {
            clearInterval(interval);
            abort();
        }
    }, [ refetch, abort ]);

    useEffect(() => {
        if (data && !error) {
            setCurrentData(data);
        }
    }, [data, error]);

    const [seconds, setSeconds] = useState(0);
    const interval = useInterval(() => setSeconds((s) => s + 0.01), 10);

    useEffect(() => {
        interval.start();
        return interval.stop;
    }, [ interval ]);

    useEffect(() => {
        let index = Math.floor(seconds / timeToChange);
        if (index >= limit) {
            index = 0;
            setSeconds(0);
        }
        let post = currentData?.data?.posts[index];
        if (!post || !post.runtime_type || post.runtime_type !== 'default') {
            setSeconds(seconds + timeToChange);
            return;
        }
        setCurrentPost(post);
    }, [seconds, currentData]);

    // useEffect(() => {
    //     if (currentData?.data?.posts) {
    //         setCurrentPost(currentData?.data?.posts[currentPostIndex]);
    //     }
    // }, [currentPostIndex, currentData]);

    const warperRef = useRef<HTMLDivElement>(null);

    return (
        <Flex direction="column" h="100%"
            ref={warperRef}
            style={{
                ...style,
                // backgroundImage: 'url(https://i.pinimg.com/originals/c8/ba/30/c8ba30ee61944cc26dbde4022a0a4b72.gif), linear-gradient(45deg, #00a9dd 0%, #005b77 100%)',
                position: 'relative',
                overflow: 'hidden',
            }}
            p="lg"
            justify={"space-around"}
        >
            { withHeader && <NewsFeedHeader /> }
            <Box
                style={{
                    width: '100%',
                    // padding: '10px',
                    // borderBottom: '3px solid',
                    // borderColor: currentPost?.colors?.primary,
                    position: 'relative',
                }}
                mt={'lg'}
            >
                <Box
                    p={'xs'}
                    bg={'white'}
                    w={'fit-content'}
                    pos={'absolute'}
                    top={0}
                    style={{
                        ...(dir === 'rtl' ? { left: 0 } : { right: 0 }),
                        borderRadius: 10,
                        overflow: 'hidden',
                    }}
                >
                    <Image
                        src={'https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=' + (currentPost?.permalink as string) + '?utm_source=964media&utm_medium=web&utm_campaign=stream'}
                        w={100}
                        style={{
                            // position: 'absolute',
                            // borderRadius: '10px',
                        }}
                    />
                </Box>
                <Image
                    src={currentPost?.featured_image?.sizes?.large}
                    alt={currentPost?.title}
                    style={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                        borderRadius: '10px',
                        aspectRatio: '16 / 9',
                        maxHeight: '350px',
                    }}
                    mb={'md'}
                />
                <Text
                    style={{
                        // color: post.colors?.primary,
                        fontSize: '2.5rem',
                        lineHeight: '3.5rem',
                        color: colorScheme === 'dark' ? 'white' : 'black',
                        
                        // max lines 2
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: 'vertical',
                    }}
                    fw={"bold"}
                >
                    {currentPost?.title}
                </Text>
                <Flex w={'100%'} align={'center'} pt={'xs'} gap={'sm'}>
                    <Box
                        style={{
                            borderBottom: '5px solid',
                            borderColor: currentPost?.colors?.primary,
                            width: `${(seconds % timeToChange) / timeToChange * 100}%`,
                        }}
                    />
                    <Box
                        style={{
                            // position: 'absolute',
                            // bottom: 10,
                            // ...(dir === 'rtl' ? { left: '0px' } : { right: '0px' }),
                        }}
                        mt={7}
                    >
                        <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56 56" width="15" height="15">
                            <g id="Layer_1-2">
                                <g>
                                    <path fill={currentPost?.colors?.primary} d="m55.6 18.2h-18.2v-18.2h-19.2v18.2h-18.2v19.2h18.2v18.2h19.2v-18.2h18.2v-19.2z" />
                                </g>
                            </g>
                        </svg>
                    </Box>
                </Flex>
            </Box>
        </Flex>
    )

}

// https://api.open-meteo.com/v1/forecast?latitude=33.34322947245154&longitude=44.399152998524535&current=temperature_2m,wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m

// const getWeather = async (geostring: string) => {
//     const geo = geostring.split(',');
//     if (geo.length !== 2) {
//         return;
//     }
//     const latitude = geo[0];
//     const longitude = geo[1];
//     const url = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current=temperature_2m,wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m`;
//     const response = await fetch(url);
//     const data = await response.json();
//     return data;
// }