import { Box, Flex, Text, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import { ForexMini, Logo, NewsFeedSlider, NewsFeedSliderWithContent, NewsTicker, QararMini, VideoBackground, WeatherMini, WiresFeed } from "../../components";
import { useTranslation } from "react-i18next";

export const View10 = () => {

    const theme = useMantineTheme();
    const colorScheme = useMantineColorScheme();
    const spacing = 10;

    const [cardStyle] = useSetState({
        // backgroundColor: colorScheme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        backgroundColor: '#fff',
        borderRadius: theme.radius.md,
        padding: theme.spacing.sm,
        overflow: 'hidden',
    });

    const { t } = useTranslation();

    return (
        <Flex
            style={{
                // backgroundColor: colorScheme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
                width: '100%',
                height: '100%',
                position: 'relative',
            }}
            p={spacing}
            gap={spacing}
            direction={'column'}
        >
            {/* <VideoBackground /> */}
            <Flex
                gap={spacing}
                style={{
                    height: `8%`,
                    zIndex: 1,
                }}
            >
                <Flex
                    style={{
                        ...cardStyle,
                        backgroundColor: '#fffd',
                        width: '100%',
                        height: '100%',
                    }}
                    bg={'white'}
                    py={0}
                    px={0}
                    justify={'center'}
                    align={'center'}
                >
                    <NewsTicker step={3} />
                </Flex>
                <Logo
                    style={{
                        width: 150,
                    }}
                />
            </Flex>
            <Flex
                gap={spacing}
                style={{
                    height: `92%`,
                    zIndex: 1,
                }}
            >
                <Flex direction={'row'} gap={spacing} style={{ width: '100%', height: '100%' }}>
                    <Flex direction={'column'} gap={spacing} style={{ width: '62%', height: '100%' }}>
                        <Box
                            style={{
                                ...cardStyle,
                                backgroundColor: '#000b',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <WiresFeed />
                        </Box>
                        <Flex
                            gap={spacing}
                            style={{
                                height: `20%`,
                            }}
                        >
                            {/* <Box
                                style={{
                                    ...cardStyle,
                                    width: '20%',
                                    height: '100%',
                                }}
                            >
                                <Flex justify={'center'} align={'center'} style={{ height: '100%' }} direction={'column'} gap={'lg'}>
                                    <Box
                                        style={{
                                            backgroundColor: 'white',
                                            padding: '10px',
                                            borderRadius: '10px',
                                        }}
                                    >
                                        <img width={200} src="https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=https://964media.com/app/download" alt="QR Code" />
                                    </Box>
                                    <Text size={'2rem'} fw={'bold'}>
                                        {t('حمل التطبيق الآن')}
                                    </Text>
                                </Flex>
                            </Box> */}
                            <WeatherMini
                                style={{
                                    ...cardStyle,
                                    width: '20%',
                                    height: '100%',
                                }}
                            />
                            <ForexMini
                                style={{
                                    ...cardStyle,
                                    width: '20%',
                                    height: '100%',
                                }}
                            />
                            <QararMini
                                style={{
                                    ...cardStyle,
                                    width: '60%',
                                    height: '100%',
                                }}
                            />
                            {/* <Box
                    style={{
                        ...cardStyle,
                        width: '20%',
                        height: '100%',
                        backgroundImage: 'url(https://964tube.com/wp-content/uploads/2024/08/B5NAckADlFjgrtKKoeQGcYqs07KhbujSQ5ragjQrVb5BAHu1HRunrEb17tSn.jpg)',
                        backgroundSize: 'cover',
                    }}
                >
                </Box> */}
                        </Flex>
                    </Flex>
                    <Flex style={{ width: '38%', height: '100%' }} gap={spacing}>
                        <Box
                            style={{
                                ...cardStyle,
                                backgroundColor: '#000b',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <NewsFeedSliderWithContent withHeader={false} />
                        </Box>
                    </Flex>

                </Flex>
            </Flex>

        </Flex>
    );
}