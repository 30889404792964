import { Box, Flex, Image, Text, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { Swiper } from ".";
import { useFetch, useInterval } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import QararLogo from "../assets/img/qarar.svg";

export const Qarar = ({
    style = {},
}: {
    style?: React.CSSProperties,
}) => {


    const { data, refetch } = useFetch<any>(
        'https://curiosity.qarar.me/rest/qarar-app/v2/ar/opinions/feed'
    );

    const interval = useInterval(() => {
        refetch();
    }, 60000);

    useEffect(() => {
        interval.start();
        return interval.stop;
    }, [interval]);

    const [opinions, setOpinions] = useState<any>([]);

    useEffect(() => {
        if (data?.data?.opinions) {
            // setOpinions(data.data.opinions);
            let newOpinions: any = [];
            let i = 0;
            data.data.opinions.forEach((opinion: any) => {
                newOpinions.push(
                    <OpinionItem opinion={opinion} />
                )
                if (i % 5 === 0) {
                    newOpinions.push(
                        <QararDownload />
                    )
                }
                i++;
            });
            setOpinions(newOpinions);
        }
    }, [data]);

    // #e80c14
    // #ff6060
    // #fcd903
    // #579bfc
    // #0753d9

    return (
        <Swiper
            style={{
                ...style,
                backgroundColor: '#fff',
            }}
            items={opinions}
            timeToChange={5}
        />
    );

}

export const QararDownload = () => {

    const { t } = useTranslation();

    return (
        <Flex justify={'center'} align={'center'} style={{ height: '100%' }} direction={'row'} gap={'sm'}>
            <Box
                style={{
                    backgroundColor: 'white',
                    padding: '10px',
                    borderRadius: '10px',
                }}
            >
                <img width={200} src="https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=https://qarar.me/app/download" alt="QR Code" />
            </Box>
            <Text
                size={'3rem'}
                fw={'bolder'}
                style={{
                    color: '#000',
                    // fontFamily: 'Lalezar',
                }}>
                {t('صوت الآن')}
            </Text>
        </Flex>
    )
}

export const OpinionItem = ({
    opinion,
}: {
    opinion: any,
}) => {

    const { t } = useTranslation();

    return (
        <Flex
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#fff',
                position: 'relative',
                // backgroundImage: 'linear-gradient(45deg, #e51e27 0%, #681519 100%)',
                // animation: 'gradient 15s ease infinite',
            }}
        >
            <Box
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    // backgroundImage: 'conic-gradient(#e80c14, #ff6060, #fcd903, #579bfc, #0753d9, #e80c14)',
                    backgroundSize: '200% 200%',
                    animation: 'gradient 15s ease infinite',
                    zIndex: 1,
                }}
            />
            <Flex
                style={{
                    // textAlign: 'center',
                    // border: '5px solid #000',
                    borderRadius: '10px',
                    backgroundColor: '#fff',
                    zIndex: 2,
                }}
                w={'calc(100%)'}
                gap={'md'}
                h={'calc(100% - 0px)'}
                align={'center'}
            >
                <Box
                    h={'100%'}
                    w={'max-content'}
                    style={{
                        position: 'relative',
                        aspectRatio: 1,
                    }}
                >
                    <Image src={opinion?.featured_image?.sizes?.medium} h={'100%'} />
                    <Box
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: -1,
                            width: '100%',
                            height: '100%',
                            // backgroundImage: 'linear-gradient(-90deg, #fff0 60%, #fff 100%)',
                        }}
                    />
                </Box>
                <Flex
                    direction={'column'}
                    gap={'md'}
                >
                    <Image src={QararLogo} w={50} />
                    <Title
                        fw={"bolder"}
                        pe={"md"}
                        style={{
                            fontSize: '2.5rem',
                            color: '#000',
                            // fontFamily: 'Lalezar',
                        }}
                    >
                        {opinion.title}
                        {' '}
                        {t('ما رأيك؟')}
                    </Title>
                </Flex>
            </Flex>
        </Flex>
    )

}