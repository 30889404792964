import { Flex } from "@mantine/core"
import { Logo } from "./logo"

export const NewsFeedHeader = () => {

    return (
        <Flex
            w="100%"
            justify="end"
        >
            <Logo
                style={{
                    width: '20%',
                }}
            />
        </Flex>
    )

}