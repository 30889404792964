import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
// import {
//     QueryClient,
//     QueryClientProvider,
// } from '@tanstack/react-query';

import './i18n';
import 'dayjs/locale/ar';
import ThemeProvider from './themeProvider';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// const queryClient = new QueryClient()

root.render(
  <React.StrictMode>
    {/* <QueryClientProvider client={queryClient}> */}
    <BrowserRouter>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </BrowserRouter>
    {/* </QueryClientProvider> */}
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
