import { Navigate, useRoutes } from 'react-router-dom';

import { StreamLayout } from './layouts';
import { View1, View10, View2, View3, View4, View5, View6, View7, View8, View9 } from './views/stream';
import { Forex, Qarar, Weather } from './widget';
import { NewsFeed, NewsFeedSlider, NewsFeedSliderWithContent, NewsTicker, QararMini, QararVertical, WiresFeed } from './components';
import { WeatherScreen } from './screen';
import { Weather1000 } from './screen/weather1000';

export default function Router() {

    return useRoutes([
        {
            path: '/:lang',
            element: <StreamLayout />,
            children: [
                {
                    path: '',
                    element: <View1 />,
                },
                {
                    path: 'screen',
                    children: [
                        {
                            'path': '',
                            'element': <Navigate to="/" />
                        },
                        {
                            'path': 'weather',
                            'element': <WeatherScreen />,
                        },
                        {
                            'path': 'weather1000',
                            'element': <Weather1000 />,
                        },
                    ],
                },
                {
                    path: 'widget',
                    children: [
                        {
                            'path': '',
                            'element': <Navigate to="/" />
                        },
                        {
                            'path': 'forex',
                            'element': <Forex />,
                        },
                        {
                            'path': 'weather',
                            'element': <Weather />,
                        },
                        {
                            'path': 'qarar',
                            'element': <Qarar />,
                        },
                        {
                            'path': 'qarar-vertical',
                            'element': <QararVertical style={{ borderRadius: '8px' }} />,
                        },
                        {
                            'path': 'news-feed-slider',
                            'element': <NewsFeedSlider withHeader={false} />,
                        },
                        {
                            'path': 'news-feed-slider-with-content',
                            'element': <NewsFeedSliderWithContent withHeader={false} />,
                        },
                        {
                            'path': 'news-ticker',
                            'element': <NewsTicker />,
                        },
                        {
                            'path': 'wires',
                            'element': <WiresFeed />,
                        },
                    ],
                },
                {
                    path: 'view',
                    children: [
                        {
                            'path': '',
                            'element': <Navigate to="/" />
                        },
                        {
                            path: '1',
                            element: <View1 />,
                        },
                        {
                            path: '2',
                            element: <View2 />,
                        },
                        {
                            path: '3',
                            element: <View3 />,
                        },
                        {
                            path: '4',
                            element: <View4 />,
                        },
                        {
                            path: '5',
                            element: <View5 />,
                        },
                        {
                            path: '6',
                            element: <View6 />,
                        },
                        {
                            path: '7',
                            element: <View7 />,
                        },
                        {
                            path: '8',
                            element: <View8 />,
                        },
                        {
                            path: '9',
                            element: <View9 />,
                        },
                        {
                            path: '10',
                            element: <View10 />,
                        },
                    ],
                },
            ]
        },
    ]);

}


