import LogoWhite from '../assets/img/logo-white.svg';
import LogoBlack from '../assets/img/logo-black.svg';
import { useMantineColorScheme } from '@mantine/core';
import { useEffect, useState } from 'react';

export const Logo = ({
    invert = false,
    ...props
}: {
    invert?: boolean;
    [key: string]: any;
}) => {
    const colorScheme = useMantineColorScheme();
    const [logo, setLogo] = useState(LogoWhite);

    useEffect(() => {
        if (invert) {
            setLogo(colorScheme.colorScheme === 'dark' ? LogoBlack : LogoWhite);
        } else {
            setLogo(colorScheme.colorScheme === 'dark' ? LogoWhite : LogoBlack);
        }
    }, [colorScheme, invert]);

    return (
        <img
            alt="964stream"
            {...props}
            src={logo}
        />
    );
}