import { Box, Flex, Text, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import { Forex, Logo, NewsFeedSlider, NewsTicker, Qarar, Weather } from "../../components";
import { useTranslation } from "react-i18next";

export const View3 = () => {

    const theme = useMantineTheme();
    const colorScheme = useMantineColorScheme();
    const spacing = 20;

    const [cardStyle] = useSetState({
        backgroundColor: colorScheme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        borderRadius: theme.radius.md,
        padding: theme.spacing.sm,
        overflow: 'hidden',
    });

    const { t } = useTranslation();

    return (
        <Flex
            style={{
                backgroundColor: colorScheme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
                width: '100%',
                height: '100%',
            }}
            p={spacing}
            gap={spacing}
            direction={'column'}
        >
            <Flex
                gap={spacing}
                style={{
                    height: `8%`,
                }}
            >
                <Flex
                    style={{
                        ...cardStyle,
                        width: '100%',
                        height: '100%',
                    }}
                    bg={'white'}
                    py={0}
                    px={0}
                    justify={'center'}
                    align={'center'}
                >
                    <NewsTicker />
                </Flex>
                <Logo
                    style={{
                        width: 150,
                    }}
                />
            </Flex>
            <Flex direction={'row'} h={'100%'} w={'100%'} gap={spacing} justify={'space-between'}>
                <Flex
                    gap={spacing}
                    style={{
                        height: `100%`,
                        width: '49%',
                    }}
                    direction={'column'}
                >
                    <Box
                        style={{
                            ...cardStyle,
                            width: '100%',
                            height: '65%',
                        }}
                    >
                        <NewsFeedSlider withHeader={false} />
                    </Box>
                    <Box
                        style={{
                            ...cardStyle,
                            width: '100%',
                            height: '35%',
                            backgroundColor: 'white',
                        }}
                    >
                        <Qarar
                            style={{
                                ...cardStyle,
                                width: '100%',
                                height: '100%',
                            }}
                        />
                    </Box>
                </Flex>
                <Box
                    style={{
                        ...cardStyle,
                        // width: '35%',
                        // height: '100%',
                        aspectRatio: '9/16',
                    }}
                />
                <Flex
                    gap={spacing}
                    style={{
                        height: `100%`,
                        width: '20%',
                    }}
                    direction={'column'}
                >
                    <Box
                        style={{
                            ...cardStyle,
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Flex justify={'center'} align={'center'} style={{ width: '100%' }} direction={'column'} gap={'lg'}>
                            <Box
                                style={{
                                    backgroundColor: 'white',
                                    padding: '10px',
                                    borderRadius: '10px',
                                }}
                            >
                                <img width={170} src="https://api.qrserver.com/v1/create-qr-code/?size=350x350&data=https://964media.com/app/download" alt="QR Code" />
                            </Box>
                            <Text size={'2rem'} fw={'bold'}>
                                {t('حمل التطبيق الآن')}
                            </Text>
                        </Flex>
                    </Box>
                    <Weather
                        style={{
                            ...cardStyle,
                            width: '100%',
                            height: '33.33%',
                        }}
                    />
                    <Forex
                        style={{
                            ...cardStyle,
                            width: '100%',
                            height: '33.33%',
                        }}
                    />
                    {/* <Box
                    style={{
                        ...cardStyle,
                        width: '20%',
                        height: '100%',
                        backgroundImage: 'url(https://964tube.com/wp-content/uploads/2024/08/B5NAckADlFjgrtKKoeQGcYqs07KhbujSQ5ragjQrVb5BAHu1HRunrEb17tSn.jpg)',
                        backgroundSize: 'cover',
                    }}
                >
                </Box> */}
                </Flex>
            </Flex>
        </Flex>
    );
}