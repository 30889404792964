import { Box, Flex, Text, useMantineColorScheme, useMantineTheme } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import { Forex, ForexMini, Logo, NewsFeedSlider, NewsFeedSliderWithContent, NewsTicker, Qarar, QararVertical, VideoBackground, Weather, WeatherMini } from "../../components";
import { useTranslation } from "react-i18next";

export const View6 = () => {

    const theme = useMantineTheme();
    const colorScheme = useMantineColorScheme();
    const spacing = 20;

    const [cardStyle] = useSetState({
        backgroundColor: colorScheme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
        borderRadius: theme.radius.md,
        padding: theme.spacing.sm,
        overflow: 'hidden',
    });

    const { t } = useTranslation();

    return (
        <Flex
            style={{
                backgroundColor: colorScheme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[0],
                width: '100%',
                height: '100%',
            }}
            p={spacing}
            gap={spacing}
            direction={'column'}
        >
            <VideoBackground />
            <Flex
                gap={spacing}
                style={{
                    height: `8%`,
                    zIndex: 1,
                }}
            >
                <Flex
                    style={{
                        ...cardStyle,
                        width: '100%',
                        height: '100%',
                    }}
                    bg={'white'}
                    py={0}
                    px={0}
                    justify={'center'}
                    align={'center'}
                >
                    <NewsTicker />
                </Flex>
                <Logo
                    style={{
                        width: 150,
                    }}
                />
            </Flex>
            <Flex direction={'row'} h={'100%'} w={'100%'} gap={spacing} justify={'space-between'} style={{ zIndex: 1 }}>
                <Flex
                    gap={spacing}
                    style={{
                        height: `100%`,
                        width: '43%',
                    }}
                    direction={'column'}
                >
                    <Box
                        style={{
                            ...cardStyle,
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#000b',
                        }}
                    >
                        <NewsFeedSliderWithContent withHeader={false} />
                    </Box>
                </Flex>
                <Box
                    style={{
                        // ...cardStyle,
                        // width: '35%',
                        // height: '100%',
                        aspectRatio: '9/16',
                    }}
                />
                <Flex
                    gap={spacing}
                    style={{
                        height: `100%`,
                        width: '25%',
                    }}
                    direction={'column'}
                >
                    <Box
                        style={{
                            ...cardStyle,
                            width: '100%',
                            backgroundColor: '#fff',
                        }}
                        py={'md'}
                    >
                        {/* show date and time */}
                        <Text
                            style={{
                                color: '#000',
                                fontSize: '1.8rem',
                                fontWeight: 'bold',
                                direction: 'ltr',
                            }}
                        >
                            {new Date().toLocaleString()} GMT+3
                        </Text>
                    </Box>
                    <QararVertical
                        style={{
                            ...cardStyle,
                            width: '100%',
                            height: '100%',
                        }}
                    />
                    <Flex
                        h={200}
                        gap={spacing}
                    >
                        <WeatherMini
                            style={{
                                ...cardStyle,
                                width: '100%',
                                height: '33.33%',
                            }}
                        />
                        <ForexMini
                            style={{
                                ...cardStyle,
                                width: '100%',
                                height: '33.33%',
                            }}
                        />
                    </Flex>
                    {/* <Box
                    style={{
                        ...cardStyle,
                        width: '20%',
                        height: '100%',
                        backgroundImage: 'url(https://964tube.com/wp-content/uploads/2024/08/B5NAckADlFjgrtKKoeQGcYqs07KhbujSQ5ragjQrVb5BAHu1HRunrEb17tSn.jpg)',
                        backgroundSize: 'cover',
                    }}
                >
                </Box> */}
                </Flex>
            </Flex>
        </Flex>
    );
}