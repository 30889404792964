import { Box, Flex, useMantineTheme } from "@mantine/core";
import { QararMini } from "../components";

export const Qarar = () => {

    const theme = useMantineTheme();

    return (
        // <Box
        //     style={{
        //         width: '100vw',
        //         height: '100%',
        //     }}
        // >
        // </Box>
        <QararMini
            style={{
                backgroundColor: '#fff',
                borderRadius: theme.radius.md,
                padding: theme.spacing.sm,
                overflow: 'hidden',
                width: '100%!important',
                height: '100%',
            }}
        />
    );

};