import LoopBG from "../assets/video/loop_tv1.mp4";

export const VideoBackground = () => {

    return (
        <video autoPlay muted loop style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            top: 0,
            left: 0,
            // opacity: 0.5,
        }}>
            <source src={LoopBG} type="video/mp4" />
        </video>
    )

};