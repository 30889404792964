import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Flex, Loader, Text, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import icons from '../json/weather.json';
import { NewsTicker, Swiper } from "../components";
import { useTimeout, useViewportSize } from "@mantine/hooks";

let locations = [
    {
        name: "بغداد",
        slug: "baghdad",
        latitude: 33.3141,
        longitude: 44.3790,
    },
    {
        name: "أربيل",
        slug: "erbil",
        latitude: 36.1921,
        longitude: 44.0109,
    },
    {
        name: "البصرة",
        slug: "basra",
        latitude: 30.5101,
        longitude: 47.7803,
    },
    {
        name: "كربلاء",
        slug: "karbala",
        latitude: 32.6169,
        longitude: 44.0244,
    },
    {
        name: "سامراء",
        slug: "samarra",
        latitude: 34.2019,
        longitude: 43.8857,
    },
    {
        name: "دهوك",
        slug: "duhok",
        latitude: 36.8671,
        longitude: 42.9900,
    },
    {
        name: "تكريت",
        slug: "tikrit",
        latitude: 34.6085,
        longitude: 43.6850,
    },
    {
        name: "العمارة",
        slug: "amara",
        latitude: 31.8456,
        longitude: 47.1756,
    },
    {
        name: "الناصرية",
        slug: "nasiriyah",
        latitude: 31.0429,
        longitude: 46.2676,
    },
    {
        name: "الموصل",
        slug: "mosul",
        latitude: 36.3544,
        longitude: 43.1436,
    },
    {
        name: "النجف",
        slug: "najaf",
        latitude: 32.0255,
        longitude: 44.3464,
    },
    {
        name: "السماوة",
        slug: "samawa",
        latitude: 31.8416,
        longitude: 45.2944,
    },
    {
        name: "السليمانية",
        slug: "sulaymaniyah",
        latitude: 35.5612,
        longitude: 45.4375,
    },
    {
        name: "الديوانية",
        slug: "diwaniyah",
        latitude: 31.9631,
        longitude: 44.9252,
    },
    {
        name: "بعقوبة",
        slug: "baqubah",
        latitude: 33.7476,
        longitude: 44.6074,
    },
    {
        name: "الفلوجة",
        slug: "fallujah",
        latitude: 33.3566,
        longitude: 43.7867,
    },
    {
        name: "الكوت",
        slug: "kut",
        latitude: 32.5184,
        longitude: 45.8183,
    },
    {
        name: "الحلة",
        slug: "hilla",
        latitude: 32.4831,
        longitude: 44.4337,
    },
    {
        name: "الفاو",
        slug: "fao",
        latitude: 29.9744,
        longitude: 48.4730,
    },
    {
        name: "زاخو",
        slug: "zakho",
        latitude: 37.1383,
        longitude: 42.6872,
    },
    {
        name: "هيت",
        slug: "hit",
        latitude: 33.6206,
        longitude: 42.8114,
    },
    {
        name: "كركوك",
        slug: "kirkuk",
        latitude: 35.4681,
        longitude: 44.3922,
    },
    {
        name: "چومان",
        slug: "choman",
        latitude: 36.71897660822934,
        longitude: 44.64480502876617,
    },
]

export const Weather1000 = () => {

    // prefetch weather data for all locations
    const [xhttpLoading, setXhttpLoading] = useState(true);
    const [imagesLoading, setImagesLoading] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [locationsRef, setLocationsRef] = useState<any>([]);
    const [data, setData] = useState<any>({});
    // const [background, setBackground] = useState('');

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let locationsToload = locations;
        let userLocations: any = searchParams.get('locations');

        if (userLocations) {
            userLocations = userLocations.split(',');
            locationsToload = locations.filter(location => userLocations.includes(location.slug));

        }
        locations = locationsToload;

        locationsToload.forEach((location, index) => {
            var xhttp = new XMLHttpRequest();
            xhttp.onreadystatechange = function () {
                if (this.readyState === 4 && this.status === 200) {
                    const response = JSON.parse(this.responseText);
                    setData((prev: any) => {
                        return {
                            ...prev,
                            [location.name]: response,
                        }
                    });

                    if (index === locationsToload.length - 1) {
                        setXhttpLoading(false);
                    }
                }
            };

            xhttp.open("GET", `https://api.open-meteo.com/v1/forecast?latitude=${location.latitude}&longitude=${location.longitude}&daily=temperature_2m_max,temperature_2m_min,showers_sum,snowfall_sum,wind_speed_10m_max,wind_gusts_10m_max,weather_code&current=temperature_2m,relative_humidity_2m,apparent_temperature,is_day,precipitation,rain,showers,snowfall,weather_code,cloud_cover,pressure_msl,surface_pressure&timezone=GMT&daily=temperature_2m_max,temperature_2m_min,showers_sum,snowfall_sum,wind_speed_10m_max,wind_gusts_10m_max,weather_code`, true);
            xhttp.send();
        });
    }, [searchParams]);

    const [loadedImages, setLoadedImages] = useState(0);

    // const onImageLoad = useMemo(() => {
    //     return () => {
    //         const total = icons.map(icon => icon.backgrounds).flat().length;
    //         setLoadedImages((prev) => prev + 1);
    //         console.log(loadedImages, total);

    //         if (loadedImages === total) {
    //             setImagesLoading(false);
    //         }
    //     }
    // }, [loadedImages]);

    const [imagesBinaries, setImagesBinaries] = useState<any>([]);

    // preload all background images before displaying the weather data
    useEffect(() => {
        if (!imagesLoading) {
            return;
        }
        const imageUrls = icons.map(icon => icon.backgrounds).flat();
        const images = imageUrls.map(url => {
            // const image = new Image();
            // image.onload = onImageLoad;
            // image.src = url;
            // return image;

            // download image as binary data to avoid flickering
            return new Promise((resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function () {
                    if (this.status === 200) {
                        const uInt8Array = new Uint8Array(this.response);
                        const i = uInt8Array.length;
                        const binaryString = new Array(i);
                        for (let i = 0; i < uInt8Array.length; i++) {
                            binaryString[i] = String.fromCharCode(uInt8Array[i]);
                        }
                        const data = binaryString.join('');
                        const base64 = window.btoa(data);
                        const binary = {
                            url: url,
                            object: `data:image/png;base64,${base64}`,
                        };
                        resolve(binary);

                    } else {
                        reject();
                    }
                };
                xhr.send();
            });
        });

        Promise.all(images).then((binaries) => {
            setImagesBinaries(binaries);
            setTimeout(() => {
                setImagesLoading(false);
            }, 5000);
        });
    }, []);

    useEffect(() => {
        if (locationsRef && locationsRef[currentIndex]) {
            const parent = locationsRef[currentIndex].parentElement;
            const container = parent?.parentElement;
            if (container) {
                const item = locationsRef[currentIndex];
                const itemOffset = item.offsetLeft + item.offsetWidth / 2;
                const containerOffset = container.offsetWidth / 2;
                container.scrollTo({
                    left: itemOffset - containerOffset,
                    behavior: 'smooth'
                });
            }
        }
    }, [locationsRef, currentIndex, data]);

    return (
        <Flex w={'100vw'} h={'100vh'} justify={'center'} align={'center'}
            style={{
                // backgroundImage: `url(${background})`,
                backgroundSize: '150%',
                backgroundPosition: '100% 50%',
                animation: 'backgroundScroll 60s linear infinite',
                overflow: 'scroll',
            }}
        >
            {
                (imagesLoading || xhttpLoading) ? (
                    <Loader
                        color="white"
                        size={100}
                        type="bars"
                    />
                ) : (
                    <>
                        <Box
                            style={{
                                position: 'absolute',
                                width: '100vw',
                                height: '100vh',
                            }}
                        >
                            <Swiper
                                style={{
                                    width: '100vw',
                                    height: '100vh',
                                    position: 'absolute',
                                }}
                                items={
                                    locations.map((location, index) => {

                                        const currentWeatherCode = data[location.name]?.current?.weather_code;
                                        const icon = icons.find(icon => icon.codes.includes(currentWeatherCode)) || icons[0];
                                        const backgrounds = icon.backgrounds;
                                        const background = backgrounds[Math.floor(Math.random() * backgrounds.length)];
                                        const binary = imagesBinaries.find((binary: any) => binary.url === background);

                                        return (
                                            <Box
                                                key={index}
                                                style={{
                                                    backgroundImage: `url(${binary?.object})`,
                                                    backgroundSize: '150%',
                                                    backgroundPosition: '100% 50%',
                                                    animation: 'backgroundScroll 60s linear infinite',
                                                    width: '100vw',
                                                    height: '100vh',
                                                    position: 'absolute',
                                                }}
                                            />
                                        )

                                    })
                                }
                                timeToChange={10}
                            />
                        </Box>
                        <Flex direction={'column'} w={'100vw'} h={'100%'}>
                            <Box
                                style={{
                                    width: '100vw',
                                    overflow: 'hidden',
                                    position: 'relative',
                                    zIndex: 1,
                                    padding: '150px 0 20px 0',
                                    background: 'linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%)',
                                }}
                            >
                                <Flex direction={'row'} align={'center'} justify={'center'} gap={50}
                                    style={{
                                        width: 'max-content',
                                        padding: `0 ${1920 / 2}px`
                                    }}
                                >
                                    {
                                        locations.map((location, index) => {
                                            return (
                                                <Title
                                                    key={index}
                                                    style={{
                                                        color: 'white',
                                                        // fontSize: '2.5rem',
                                                        transition: 'all 0.5s ease',
                                                        opacity: index === currentIndex ? 1 : 0.5,
                                                        fontSize: index === currentIndex ? '3rem' : '2rem',
                                                    }}
                                                    ref={(ref) => {
                                                        locationsRef[index] = ref;
                                                        setLocationsRef(locationsRef);
                                                    }}
                                                >
                                                    {location.name}
                                                </Title>
                                            )
                                        })
                                    }
                                </Flex>
                            </Box>
                            <Swiper
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    zIndex: 1,
                                }}
                                items={
                                    locations.map((location, index) => {
                                        return (
                                            <WeatherLocation
                                                key={index}
                                                data={data[location.name]}
                                                name={location.name}
                                            />
                                        )
                                    })
                                }
                                onChange={(index: number) => setCurrentIndex(index)}
                                timeToChange={10}
                            />
                        </Flex>
                    </>
                )
            }
        </Flex>
    )

};

export const WeatherLocation = (params: any) => {

    const { t } = useTranslation();

    const data = params.data;
    const currentWeatherCode = data?.current?.weather_code;
    const [currentWeatherSlug, setCurrentWeatherSlug] = useState('');

    useEffect(() => {
        if (currentWeatherCode) {
            const icon = icons.find(icon => icon.codes.includes(currentWeatherCode)) || icons[0];
            const slug = icon.slug;
            setCurrentWeatherSlug(slug);
        }
    }, [currentWeatherCode]);

    return (
        <Flex direction={'column'} align={'center'} justify={'center'} gap={'lg'}
            style={{
                width: '100%',
                height: '100%',
            }}
        >
            <Flex direction={'column'} align={'center'} justify={'center'}>
                <Flex direction={'row'} align={'center'} justify={'space-between'} gap={'lg'}
                    style={{
                        color: 'black',
                        backgroundColor: '#fffd',
                        backdropFilter: 'blur(3px)',
                        padding: '20px 40px',
                        width: '100%',
                        borderBottom: '1px solid white',
                    }}
                >
                    <Title
                        style={{
                            fontSize: '3.5rem',
                        }}
                    >
                        {params.name || ''}
                    </Title>
                    <Flex direction={'row'} align={'center'} justify={'center'} gap={'lg'}>
                        <Flex direction={'column'}>
                            <Text
                                style={{
                                    fontSize: '1.5rem',
                                }}
                            >
                                {t('Current Weather')}
                            </Text>
                            <Title>
                                {t(currentWeatherSlug)}
                            </Title>
                        </Flex>
                        <img src={icons.find(icon => icon.codes.includes(currentWeatherCode))?.src.day} alt={'icon'} width={100} height={100} />
                        <Title style={{ direction: 'ltr' }}>
                            {data?.current?.temperature_2m} °C
                        </Title>
                    </Flex>
                </Flex>
                <Flex direction={'row'} align={'center'} justify={'center'} gap={'0'} style={{ color: 'white' }}>
                    {
                        data?.daily?.time.map((time: string, index: number) => {
                            return (
                                <WeatherStatus
                                    key={index}
                                    index={index}
                                    time={time}
                                    showers={data?.daily?.showers_sum[index]}
                                    snowfall={data?.daily?.snowfall_sum[index]}
                                    windSpeed={data?.daily?.wind_speed_10m_max[index]}
                                    windGusts={data?.daily?.wind_gusts_10m_max[index]}
                                    weatherCode={data?.daily?.weather_code[index]}
                                    maxTemperature={data?.daily?.temperature_2m_max[index]}
                                    minTemperature={data?.daily?.temperature_2m_min[index]}
                                />
                            )
                        })
                    }
                </Flex>
            </Flex>
        </Flex>
    )

}

export const WeatherStatus = (params: any) => {

    const { t, i18n } = useTranslation();

    const icon = icons.find(icon => icon.codes.includes(params.weatherCode)) || icons[0];
    const slug = icon.slug;
    const is_day = true;
    const iconSrc = is_day ? icon.src.day : icon.src.night;

    return (
        <Flex direction={'column'} align={'center'} justify={'center'} gap={'lg'}
            style={{
                color: 'white',
                backgroundColor: '#0005',
                backdropFilter: 'blur(3px)',
                padding: '20px 0',
                borderRight: params.index === 0 ? 'none' : '1px solid white',
                width: '130px',
            }}
        >
            <Title
                style={{
                    fontSize: '1.5rem',
                }}
            >
                {params.time.split('-')[1]}/{params.time.split('-')[2]}
            </Title>
            <img src={iconSrc} alt={'icon'} width={60} height={60} />
            <Title
                style={{
                    fontSize: '1.3rem',
                }}
            >
                {t(slug)}
            </Title>
            {/* temperature */}
            <Flex direction={'row'} align={'center'} justify={'center'} gap={'xs'}>
                <Title
                    style={{
                        direction: 'ltr',
                        fontSize: '1.5rem',
                    }}
                >
                    {parseFloat(String((params.maxTemperature + params.minTemperature) / 2)).toFixed(1)} °C
                </Title>
            </Flex>
        </Flex>
    )

}