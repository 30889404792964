import { Box, Flex, NumberFormatter, Text } from "@mantine/core";
import { useEffect } from "react";
import { Swiper } from ".";
import { IconMapPin } from "@tabler/icons-react";
import { useFetch, useInterval } from "@mantine/hooks";

export const Food = ({
    style = {},
}: {
    style?: React.CSSProperties,
}) => {


    const { data, refetch } = useFetch<any>(
        'https://964media.com/46ff3685a1985c50cea779000bb6f7b3/webtv/v1/prices_list?category=2'
    );

    const interval = useInterval(() => {
        refetch();
    }, 60000);

    useEffect(() => {
        interval.start();
        return interval.stop;
    }, []);

    return (
        <Swiper
            style={{
                ...style,
                backgroundImage: 'linear-gradient(45deg, #e51e27 0%, #681519 100%)',
            }}
            items={data ? data.map((item: any) => {
                return (
                    <FoodItem
                        name={item.title}
                        price={item.price}
                        icon={item.image}
                        place={item.location}
                    />
                )
            }) : []}
            timeToChange={3}
        />
    );

}


export const FoodItem = ({
    style = {},
    name,
    price,
    icon,
    place,
}: {
    style?: React.CSSProperties,
    name: string,
    price: string,
    icon?: string,
    place?: string,
}) => {

    return (
        <Flex direction="column" align="center" justify="center" h="100%" w="100%"
            style={{
                ...style,
                position: 'relative',
                backgroundImage: `url(${icon})`,
                backgroundSize: 'cover',
            }}
        >
            <Box
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                }}
            >
            </Box>
            <Flex direction="column" align="center" justify="center" w="100%" h="100%" style={{ position: 'relative', zIndex: 1, color: 'white' }} gap={'md'}>
                {
                    place && (
                        <Flex align="center"
                            style={{
                                position: 'absolute',
                                top: '10px',
                                right: '10px',
                                zIndex: 2,
                                backgroundColor: 'white',
                                color: 'black',
                                // padding: '5px 10px',
                            }}
                        >
                            <Box style={{ backgroundColor: '#fcd903' }} px={'xs'} pt={5}>
                                <IconMapPin size={40} />
                            </Box>
                            <Text size="2rem" fw="bold" px={'xs'}>
                                {place}
                            </Text>
                        </Flex>
                    )
                }
                <Text size="2.5rem" fw={'bold'}>
                    {name}
                </Text>
                <Flex>
                    <Text size="3rem" fw={'bold'}>
                        <NumberFormatter value={price} thousandSeparator={true} />
                    </Text>
                    <Text size="2rem" fw={'bold'}>
                        {' د.ع'}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    )

}

export const WeatherDetail = ({
    name,
    value,
}: {
    name: string,
    value: string,
}) => {
    return (
        <Flex align="center" gap={'sm'} direction={'column'}>
            <Text size="1.6rem">
                {name}
            </Text>
            <Text size="1.6rem" fw={'bold'}>
                {value}
            </Text>
        </Flex>
    )
}

// https://api.open-meteo.com/v1/forecast?latitude=33.34322947245154&longitude=44.399152998524535&current=temperature_2m,wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m

// const getWeather = async (geostring: string) => {
//     const geo = geostring.split(',');
//     if (geo.length !== 2) {
//         return;
//     }
//     const latitude = geo[0];
//     const longitude = geo[1];
//     const url = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current=temperature_2m,wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m`;
//     const response = await fetch(url);
//     const data = await response.json();
//     return data;
// }