import { Box, Flex, Text } from "@mantine/core";
import { useFetch } from "@mantine/hooks";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper } from ".";

export const WeatherMini = ({
    style = {},
}: {
    style?: React.CSSProperties,
}) => {

    const { t } = useTranslation();

    const places = [
        <WeatherPlace
            name={t('بغداد')}
            latitude={33.3406}
            longitude={44.4009}
        />,
        <WeatherPlace
            name={t('نينوى')}
            latitude={36.08333}
            longitude={42.5}
        />,
        <WeatherPlace
            name={t('البصرة')}
            latitude={30.41667}
            longitude={47.41667}
        />,
        <WeatherPlace
            name={t('أربيل')}
            latitude={36.2305}
            longitude={43.9908}
        />,
        <WeatherPlace
            name={t('كركوك')}
            latitude={35.44889672956786}
            longitude={44.38271263329815}
        />,
        <WeatherPlace
            name={t('كربلاء')}
            latitude={32.61483256433374}
            longitude={44.03287843151956}
        />,
        <WeatherPlace
            name={t('النجف')}
            latitude={32.04782571435221}
            longitude={44.366233000647796}
        />,
        <WeatherPlace
            name={t('جبل هلكورد')}
            latitude={36.73203242186626}
            longitude={44.88000554634261}
        />,
    ];

    return (
        <Swiper
            style={{
                ...style,
                backgroundImage: 'linear-gradient(45deg, #00a9dd 0%, #005b77 100%)',
            }}
            items={places}
            timeToChange={5}
        />
    );

}


const icons = [
    {
        src: {
            day: "http://openweathermap.org/img/wn/01d@2x.png", // clear
            night: "http://openweathermap.org/img/wn/01n@2x.png",
        },
        codes: [0],
    },
    {
        src: {
            day: "http://openweathermap.org/img/wn/02d@2x.png", // few_clouds
            night: "http://openweathermap.org/img/wn/02n@2x.png",
        },
        codes: [1, 2],
    },
    {
        src: {
            day: "http://openweathermap.org/img/wn/03d@2x.png", // scattered_clouds
            night: "http://openweathermap.org/img/wn/03n@2x.png",
        },
        codes: [3],
    },
    {
        src: {
            day: "http://openweathermap.org/img/wn/04d@2x.png", // broken_clouds
            night: "http://openweathermap.org/img/wn/04n@2x.png",
        },
        codes: [],
    },
    {
        src: {
            day: "http://openweathermap.org/img/wn/09d@2x.png", // shower_rain  
            night: "http://openweathermap.org/img/wn/09n@2x.png",
        },
        codes: [65, 67, 80, 81, 82],
    },
    {
        src: {
            day: "http://openweathermap.org/img/wn/10d@2x.png", // rain
            night: "http://openweathermap.org/img/wn/10n@2x.png",
        },
        codes: [51, 53, 55, 61, 63, 66, 56, 57],
    },
    {
        src: {
            day: "http://openweathermap.org/img/wn/11d@2x.png", // thunderstorm
            night: "http://openweathermap.org/img/wn/11n@2x.png",
        },
        codes: [95, 96, 99],
    },
    {
        src: {
            day: "http://openweathermap.org/img/wn/13d@2x.png", // snow
            night: "http://openweathermap.org/img/wn/13n@2x.png",
        },
        codes: [71, 73, 75, 77, 85, 86],
    },
    {
        src: {
            day: "http://openweathermap.org/img/wn/50d@2x.png", // mist
            night: "http://openweathermap.org/img/wn/50n@2x.png",
        },
        codes: [45, 48],
    },
];


export const WeatherPlace = ({
    style = {},
    name,
    latitude,
    longitude,
}: {
    style?: React.CSSProperties,
    name: string,
    latitude: number,
    longitude: number,
}) => {

    const [currentData, setCurrentData] = useState<any>({});
    // const { data, loading, error, refetch, abort } = useFetch<any>(
    const { data, refetch, abort } = useFetch<any>(
        `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current=temperature_2m,relative_humidity_2m,apparent_temperature,is_day,precipitation,rain,showers,snowfall,weather_code,cloud_cover,pressure_msl,surface_pressure&forecast_days=3`
    );

    useEffect(() => {
        const interval = setInterval(() => {
            refetch();
        }, 60000);
        return () => {
            clearInterval(interval);
            abort();
        }
    }, [refetch, abort]);

    useEffect(() => {
        if (data && data.current && data.current_units) {
            setCurrentData(data);
        }
    }, [data]);

    const { t } = useTranslation();

    const current = currentData.current || {};
    const currentUnits = currentData.current_units || {};
    const icon = icons.find(icon => icon.codes.includes(current.weather_code)) || icons[0];
    // const icon = icons.find(icon => icon.codes.includes(1)) || icons[0];
    const is_day = current.is_day ? true : false;
    const iconSrc = is_day ? icon.src.day : icon.src.night;

    return (
        <Flex direction="column" align="center" justify="center" h="100%" w="100%"
            style={{
                ...style,
                position: 'relative',
            }}
        >
            <Box
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
            >
            </Box>
            <Flex direction="row" gap={'lg'} w="100%" h="100%" style={{ position: 'relative', zIndex: 1, color: 'white' }}>
                <img
                    src={iconSrc}
                    height={200}
                    width={200}
                    style={{
                        margin: '-80px -60px',
                        position: 'absolute',
                        bottom: 20,
                        left: 0,
                        animation: 'weather 5.1s linear infinite',
                    }}
                />
                <img
                    src={iconSrc}
                    height={200}
                    width={200}
                    style={{
                        margin: '-80px -60px',
                        position: 'absolute',
                        bottom: 20,
                        left: 0,
                        animation: 'weather 5.1s linear infinite',
                        filter: 'blur(20px)',
                    }}
                />
                <Flex direction="column" gap="xs" w={'100%'} p={'lg'} style={{ zIndex: 1 }}>
                    <Text size="2.2rem" fw={'bold'}>
                        {current.temperature_2m}{currentUnits.temperature_2m}
                    </Text>
                    <Text size="2rem" fw={'bold'}
                        // text shadow
                        style={{
                            textShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        {name}
                    </Text>
                </Flex>
                {/* <Box
                    style={{
                        borderBottom: '1px solid white',
                        width: '50%',
                    }}
                    my={'sm'}
                />
                <Flex gap={'md'}>
                    {
                        current.relative_humidity_2m > 0 && (
                            <WeatherDetail
                                name={t('الرطوبة')}
                                value={current.relative_humidity_2m + currentUnits.relative_humidity_2m}
                            />
                        )
                    }
                    {
                        current.cloud_cover > 0 && (
                            <WeatherDetail
                                name={t('الغيوم')}
                                value={current.cloud_cover + currentUnits.cloud_cover}
                            />
                        )
                    }
                    {
                        current.precipitation > 0 && (
                            <WeatherDetail
                                name={t('الأمطار')}
                                value={current.precipitation + currentUnits.precipitation}
                            />
                        )
                    }
                    {
                        current.snowfall > 0 && (
                            <WeatherDetail
                                name={t('الثلوج')}
                                value={current.snowfall + currentUnits.snowfall}
                            />
                        )
                    }
                </Flex> */}
            </Flex>
        </Flex>
    )

}

export const WeatherDetail = ({
    name,
    value,
}: {
    name: string,
    value: string,
}) => {
    return (
        <Flex align="center" gap={'sm'} direction={'column'}>
            <Text size="1.6rem">
                {name}
            </Text>
            <Text size="1.6rem" fw={'bold'} style={{ direction: 'ltr' }}>
                {value}
            </Text>
        </Flex>
    )
}

// https://api.open-meteo.com/v1/forecast?latitude=33.34322947245154&longitude=44.399152998524535&current=temperature_2m,wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m

// const getWeather = async (geostring: string) => {
//     const geo = geostring.split(',');
//     if (geo.length !== 2) {
//         return;
//     }
//     const latitude = geo[0];
//     const longitude = geo[1];
//     const url = `https://api.open-meteo.com/v1/forecast?latitude=${latitude}&longitude=${longitude}&current=temperature_2m,wind_speed_10m&hourly=temperature_2m,relative_humidity_2m,wind_speed_10m`;
//     const response = await fetch(url);
//     const data = await response.json();
//     return data;
// }